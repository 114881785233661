import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ContainerBox from "../../components/containerBox";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { MainJumbotron } from "../../components/jumbotron";
import TimelineItem from "../../components/timeLineItem";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { CONTENTS, YEAR_ENUM } from "../../data/mileston";
import IntellectualProPertyJumbotron from "../../components/jumbotron/IntellectualProperty";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      paddingTop: 160,
      paddingBottom: 160,
    },
    title: {
      fontSize: "46px",
      fontWeight: 700,
      lineHeight: "1.2",
      marginBottom: 16,
    },
    text: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: "-0.5px",
      marginBottom: 160,
      color: "#858585",
    },
    markContainer: {
      marginTop: 30,
      marginBottom: 30,
      boxSizing: "border-box",
      float: "left",
    },
    markImage: {
      marginBottom: 32,
    },
  })
);

interface MilestonePageProps {
  pageContext: {
    language: string;
  };
}

function IntellectualPropertyPage({ pageContext }: MilestonePageProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { language } = pageContext;

  return (
    <Layout language={language}>
      <SEO title="History" />
      <IntellectualProPertyJumbotron />
      <ContainerBox className={classes.container}>
        <Grid container>
          <Grid item sm={12}>
            <Typography variant="h2" className={classes.title}>
              Intellectual property
            </Typography>
            <Typography component="p" className={classes.text}>
              Below is a non-exhaustive list of trademarks and service marks
              owned by Hyperconnect LLC
              <br /> <br />
              You may not use the below marks without authorization from
              Hyperconnect LLC If granted authorization and you use the below
              marks in publications distributed only in the United States,
              include the appropriate ™ at least the first use and on those
              subsequent uses where the marks appear prominently. For
              publicatins distributed outside the United States, use of the ™
              notice symbol is acceptable. It is also apropriate to use, instead
              of the trademark symbols, the trademark legends in the forms
              listed below:
            </Typography>
            <Typography variant="h2" className={classes.title}>
              Sample trademark legends
            </Typography>
            <Typography component="p" className={classes.text}>
              • For registered trademarks: HYPERCONNECT is the exclusive
              registered trademark of Hyperconnect LLC and is used with
              permission.
              <br />• For unregistered trademarks: HYPERCONNECT is the exclusive
              trademark of Hyperconnect LLC and is used with permission.
            </Typography>
            <Typography variant="h2" className={classes.title}>
              Logo
            </Typography>
            <Typography component="p" className={classes.text}>
              For the logo, please also indicate that the logo is
              copyright-protected.This can be done through either the
              combination symbol “™&© Hyperconnect LLC”, or the following legend
              (in addition to the above trademark legend):
              <br />
              <br />
              Copyright[year of first publication]Hyperconnect LLC All rights
              reserved.Used with permission.
              <br />
              <br />
              The omission of a product, service name or logo from the below
              list does not constitute a waiver of any trademark or other
              intellectual property rights related to that product, name, or
              logo.
              <br />
              <br />
              If you see a website or app that uses a Hyperconnect LLC trademark
              inappropriately, we would like to hear about it.Report
              inappropriate use of a Hyperconnect LLC trademark to ip
              @hpcnt.com.
            </Typography>
            <Typography variant="h2" className={classes.title}>
              Trademarks and Service Marks
            </Typography>
            <Grid container component="p" className={classes.text}>
              <Grid container className={classes.markContainer}>
                <Grid item xs={12} className={classes.markImage}>
                  <img src="/intellectual/221114 CI_HPCNT.png" />
                </Grid>
                <Grid item xs={12} className={classes.markImage}>
                  <img src="/intellectual/221114 CI_hyper-x-korean.png" />
                </Grid>
                <Grid item xs={12} className={classes.markImage}>
                  <img src="/intellectual/221114 CI_hyper-x.png" />
                </Grid>
              </Grid>
              <Grid container className={classes.markContainer}>
                <Grid item xs={12} className={classes.markImage}>
                  <img src="/intellectual/221114 CI_hyperconnect-korean.png" />
                </Grid>
              </Grid>
              <Grid container className={classes.markContainer}>
                <Grid item xs={12} className={classes.markImage}>
                  <img src="/intellectual/221114 CI_hyperconnect.png" />
                </Grid>
                <Grid item xs={12} className={classes.markImage}>
                  <img src="/intellectual/221114 CI_symbol-korean.png" />
                </Grid>
                <Grid item xs={12} className={classes.markImage}>
                  <img src="/intellectual/221114 CI_symbol.png" />
                </Grid>
              </Grid>
            </Grid>
            <Typography variant="h2" className={classes.title}>
              Patent List
            </Typography>
            <Typography component="p" className={classes.text}>
              Products and Services offered by Hyperconnect LLC are covered by
              one or more of the following patents:
              <br />
              <br />
              • Korean Pat. No. 10-1815176
              <br />
              • Korean Pat. No. 10-1827773
              <br />
              • Korean Pat. No. 10-1861006
              <br />
              • Korean Pat. No. 10-1917648
              <br />
              • Korean Pat. No. 10-1932844
              <br />
              • Korean Pat. No. 10-1935901
              <br />
              • Korean Pat. No. 10-1967658
              <br />
              • Korean Pat. No. 10-1979650
              <br />
              • Korean Pat. No. 10-1989842
              <br />
              • Korean Pat. No. 10-2034838
              <br />
              • Korean Pat. No. 10-2034840
              <br />
              • Korean Pat. No. 10-2034839
              <br />
              • Korean Pat. No. 10-2044242
              <br />
              • Korean Pat. No. 10-2044241
              <br />
              • Korean Pat. No. 10-2051828
              <br />
              • Korean Pat. No. 10-2056806
              <br />
              • Korean Pat. No. 10-2059170
              <br />
              • Korean Pat. No. 10-2073351
              <br />
              • Korean Pat. No. 10-2079091
              <br />
              • Korean Pat. No. 10-2120871
              <br />
              • Korean Pat. No. 10-2125735
              <br />
              • Korean Pat. No. 10-2132341
              <br />
              • Korean Pat. No. 10-2162102
              <br />
              • Korean Pat. No. 10-2219781
              <br />
              • Korean Pat. No. 10-2266888
              <br />
              • Korean Pat. No. 10-2271308
              <br />
              • Korean Pat. No. 10-2280212
              <br />
              • Korean Pat. No. 10-2282963
              <br />
              • Korean Pat. No. 10-2283972
              <br />
              • Korean Pat. No. 10-2287704
              <br />
              • Korean Pat. No. 10-2293416
              <br />
              • Korean Pat. No. 10-2293422
              <br />
              • Korean Pat. No. 10-2308636
              <br />
              • Korean Pat. No. 10-2311603
              <br />
              • Korean Pat. No. 10-2312861
              <br />
              • Korean Pat. No. 10-2315211
              <br />
              • Korean Pat. No. 10-2329481
              <br />
              • Korean Pat. No. 10-2345409
              <br />
              • Korean Pat. No. 10-2373764
              <br />
              • Korean Pat. No. 10-2374525
              <br />
              • Korean Pat. No. 10-2380333
              <br />
              • Korean Pat. No. 10-2422778
              <br />
              • Korean Pat. No. 10-2422779
              <br />
              • Korean Pat. No. 10-2455966
              <br />
              • Korean Pat. No. 10-2576636
              <br />
              • Korean Pat. No. 10-2345503
              <br />
              • Korean Pat. No. 10-2430020
              <br />
              • Korean Design No. 30-0850074
              <br />
              • Korean Design No. 30-0874547
              <br />
              • Korean Design No. 30-0874548
              <br />
              • Korean Design No. 30-0874549
              <br />
              • Korean Design No. 30-0874550
              <br />
              • Korean Design No. 30-0874551
              <br />
              • Korean Design No. 30-1062825
              <br />
              • Korean Design No. 30-1062826
              <br />
              • Korean Design No. 30-1062827
              <br />
              • Korean Design No. 30-1070119
              <br />
              • Korean Design No. 30-1070120
              <br />
              • Korean Design No. 30-1073933
              <br />
              • Korean Design No. 30-1076938
              <br />
              • Korean Design No. 30-1098667
              <br />
              • Korean Design No. 30-1098668
              <br />
              • Korean Design No. 30-1164952
              <br />
              • Korean Design No. 30-1164953
              <br />
              • Korean Design No. 30-1172402
              <br />
              • Korean Design No. 30-1186710
              <br />
              • Korean Design No. 30-1205870
              <br />
              • Korean Design No. 30-1213500
              <br />
              • Korean Design No. 30-1059322
              <br />
              • Korean Design No. 30-1059323
              <br />
              • Korean Design No. 30-1059324
              <br />
              • Korean Design No. 30-1073934
              <br />
              • Korean Design No. 30-1073935
              <br />
              • Korean Design No. 30-1073936
              <br />
              • Korean Design No. 30-1165072
              <br />
              • Korean Design No. 30-1165077
              <br />
              • Korean Design No. 30-1179814
              <br />
              • U.S. Pat. No. 10430523
              <br />
              • U.S. Pat. No. 10531042
              <br />
              • U.S. Pat. No. 10554698
              <br />
              • U.S. Pat. No. 10560659
              <br />
              • U.S. Pat. No. 10574941
              <br />
              • U.S. Pat. No. 10599734
              <br />
              • U.S. Pat. No. 10602091
              <br />
              • U.S. Pat. No. 10602090
              <br />
              • U.S. Pat. No. 10643036
              <br />
              • U.S. Pat. No. 10701315
              <br />
              • U.S. Pat. No. 10721437
              <br />
              • U.S. Pat. No. 10728499
              <br />
              • U.S. Pat. No. 10728498
              <br />
              • U.S. Pat. No. 10733082
              <br />
              • U.S. Pat. No. 10750102
              <br />
              • U.S. Pat. No. 10810276
              <br />
              • U.S. Pat. No. 10,824,820
              <br />
              • U.S. Pat. No. 10855933
              <br />
              • U.S. Pat. No. 10855727
              <br />
              • U.S. Pat. No. 10,939,071
              <br />
              • U.S. Pat. No. 10970523
              <br />
              • U.S. Pat. No. 10,949,654
              <br />
              • U.S. Pat. No. 10,965,907
              <br />
              • U.S. Pat. No. 10,972,700
              <br />
              • U.S. Pat. No. 11032512
              <br />
              • U.S. Pat. No. 11,080,325
              <br />
              • U.S. Pat. No. 11,102,450
              <br />
              • U.S. Pat. No. 11,140,356
              <br />
              • U.S. Pat. No. 11,184,582
              <br />
              • U.S. Pat. No. 11,196,777
              <br />
              • U.S. Pat. No. 11,206,362
              <br />
              • U.S. Pat. No. 11,227,129
              <br />
              • U.S. Pat. No. 11,249,886
              <br />
              • U.S. Pat. No. 11,296,898
              <br />
              • U.S. Pat. No. 11,301,534
              <br />
              • U.S. Pat. No. 11,323,659
              <br />
              • U.S. Pat. No. 11,379,672
              <br />
              • U.S. Pat. No. 11,394,922
              <br />
              • U.S. Pat. No. 11,443,134
              <br />
              • U.S. Pat. No. 11,252,373
              <br />
              • U.S. Pat. No. 11,457,077
              <br />
              • U.S. Pat. No. 11,496,709
              <br />
              • U.S. Pat. No. 11,550,860
              <br />
              • U.S. Pat. No. 11,553,157
              <br />
              • U.S. Pat. No. 11,606,397
              <br />
              • U.S. Pat. No. 11,501,564
              <br />
              • U.S. Pat. No. 11,677,904
              <br />
              • U.S. Pat. No. 11,570,402
              <br />
              • U.S. Pat. No. 11,716,424
              <br />
              • U.S. Pat. No. 11,722,638
              <br />
              • U.S. Pat. No. 11,606,533
              <br />
              • U.S. Pat. No. 11,615,777
              <br />
              • U.S. Pat. No. 11,463,655
              <br />
              • U.S. Design No. D996,460
              <br />
              • U.S. Design No. D925,594
              <br />
              • EU Pat. No. 3481058
              <br />
              • GB Pat. No. 3481058
              <br />
              • EU Design No. D217466
              <br />
              • EU Design No. DM/218603
              <br />
              • EU Design No. DM/218602
              <br />
              • EU Design No. DM/223341
              <br />
              • EU Design No. 007545306-0001
              <br />
              • EU Design No. D218604
              <br />
              • EU Design No. D218605
              <br />
              • GB Design No. DM/223341
              <br />
              • GB Design No. 90075453060001
              <br />
              • Japanese Pat. No. 6691201
              <br />
              • Japanese Pat. No. 6691199
              <br />
              • Japanese Pat. No. 6710255
              <br />
              • Japanese Pat. No. 6732977
              <br />
              • Japanese Pat. No. 6742394
              <br />
              • Japanese Pat. No. 6746662
              <br />
              • Japanese Pat. No. 6790234
              <br />
              • Japanese Pat. No. 6843359
              <br />
              • Japanese Pat. No. 6887612
              <br />
              • Japanese Pat. No. 6902762
              <br />
              • Japanese Pat. No. 6938734
              <br />
              • Japanese Pat. No. 6970950
              <br />
              • Japanese Pat. No. 6987124
              <br />
              • Japanese Pat. No. 7068401
              <br />
              • Japanese Pat. No. 7071451
              <br />
              • Japanese Pat. No. 7125688
              <br />
              • Japanese Pat. No. 7145995
              <br />
              • Japanese Pat. No. 7116124
              <br />
              • Japanese Pat. No. 7101749
              <br />
              • Japanese Pat. No. 7254114
              <br />
              • Japanese Pat. No. 7275181
              <br />
              • Japanese Pat. No. 7144479
              <br />
              • Japanese Pat. No. 7157194
              <br />
              • Japanese Design No. 1720902
              <br />
              • Japanese Design No. 1663808
              <br />
              • Chinese Pat. No. 107465886
              <br />
              • Taiwanese Design No. 110305223
              <br />
              • Taiwanese Design No. 110305222
              <br />
              • Taiwanese Design No. 110305221
              <br />
              • Indonesian Design No. IDD0000062065
              <br />• Indonesian Design No. IDD0000062064
            </Typography>
          </Grid>
        </Grid>
      </ContainerBox>
    </Layout>
  );
}

export default IntellectualPropertyPage;
